import { AccessRestrictions, AccessRestrictionsEntityType } from "Everlaw/AccessRestrictions";
import Base = require("Everlaw/Base");
import Database = require("Everlaw/Database");
import { Event } from "Everlaw/Events";
import { MinimalOrganization } from "Everlaw/MinimalOrganization";
import Project = require("Everlaw/Project");

enum RequestStatus {
    UNAPPROVED = "UNAPPROVED",
    UNCLAIMED = "UNCLAIMED",
    ACTIVE = "ACTIVE",
    CLOSED = "CLOSED",
}

enum RequestOrigin {
    MANUAL = "MANUAL",
    MESSAGE = "MESSAGE",
    ZENDESK = "ZENDESK",
    CLIENT_VERIFIED = "CLIENT_VERIFIED",
    AUTOMATED_REQUEST = "AUTOMATED_REQUEST",
}

class ClientDataAccessRequest extends Base.Object {
    get className(): string {
        return "ClientDataAccessRequest";
    }

    override id: number;
    grantorId: number;
    accessLevel: ClientDataAccessRequest.AccessLevel;
    entityId: number;
    reason: string;
    linkUrl: string;
    status: RequestStatus;
    created: number;
    duration: number;
    expiration: number;
    tags: string[] = [];
    delegateIds: number[] = [];
    clientId: number;
    origin: RequestOrigin;

    // Populated only on the ClientDataAccessTab
    events: Event[] = [];

    constructor(params: any) {
        super(params);
        this._mixin(params);
        // Shallow copy
        this.delegateIds = params.delegateIds && [...params.delegateIds];
    }
    override _mixin(params: any) {
        Object.assign(this, params);
    }

    isUnapproved(): boolean {
        return this.status === RequestStatus.UNAPPROVED;
    }
    isUnclaimed(): boolean {
        return this.status === RequestStatus.UNCLAIMED;
    }
    isActive(): boolean {
        return this.status === RequestStatus.ACTIVE;
    }
    isClosed(): boolean {
        return this.status === RequestStatus.CLOSED;
    }

    isFromZendesk(): boolean {
        return this.origin === RequestOrigin.ZENDESK;
    }
    isClientOriginated(): boolean {
        return (
            this.isFromZendesk()
            || this.origin === RequestOrigin.MESSAGE
            || this.origin === RequestOrigin.CLIENT_VERIFIED
        );
    }
    isProjectAccess(): boolean {
        return this.accessLevel === ClientDataAccessRequest.AccessLevel.PROJECT;
    }
    isDbAccess(): boolean {
        return this.accessLevel === ClientDataAccessRequest.AccessLevel.DATABASE;
    }
    isOrgAccess(): boolean {
        return this.accessLevel === ClientDataAccessRequest.AccessLevel.ORGANIZATION;
    }
    getEntity(): ClientDataAccessRequest.RequestEntity {
        if (this.isProjectAccess()) {
            return Base.get(Project, this.entityId);
        } else if (this.isDbAccess()) {
            return Base.get(Database, this.entityId);
        } else if (this.isOrgAccess()) {
            return Base.get(MinimalOrganization, this.entityId);
        } else {
            // This should never happen.
            throw new Error("Invalid CDAR entity.");
        }
    }

    getEntityDescription() {
        if (this.isProjectAccess()) {
            return "Project";
        } else if (this.isDbAccess()) {
            return "Database";
        } else if (this.isOrgAccess()) {
            return "Organization";
        }
        return "Entity";
    }

    displayEntity(): string {
        const entity = this.getEntity();
        const entityDisplay = entity
            ? this.entityId + " - " + entity.display()
            : this.entityId.toString();
        return entityDisplay;
    }

    getEntityOwningOrgId(): number {
        if (this.isOrgAccess()) {
            return this.entityId;
        }

        const entity = this.getEntity();
        if (entity == null) {
            return -1;
        } else if (entity instanceof Project) {
            return entity.owningOrganizationId;
        } else if (entity instanceof Database) {
            return entity.owningOrgId;
        }
        return -1;
    }

    private accessRestrictionsEntityType() {
        if (this.isProjectAccess()) {
            return AccessRestrictionsEntityType.PROJECT;
        } else if (this.isDbAccess()) {
            return AccessRestrictionsEntityType.DATABASE;
        } else if (this.isOrgAccess()) {
            return AccessRestrictionsEntityType.ORGANIZATION;
        }
        throw new Error("Unsupported entity type");
    }

    accessRestrictions(): Promise<AccessRestrictions> {
        return AccessRestrictions.fromEntity(this.accessRestrictionsEntityType(), this.entityId);
    }

    hasCjisData(): Promise<boolean> {
        return this.accessRestrictions().then((restrictions) => restrictions.cjis);
    }

    entity(): ClientDataAccessRequest.Entity {
        return new ClientDataAccessRequest.Entity(this.entityId, this.accessLevel);
    }
}

module ClientDataAccessRequest {
    export type RequestEntity = Project | Database | MinimalOrganization;

    export enum AccessLevel {
        PROJECT = "PROJECT",
        DATABASE = "DATABASE",
        ORGANIZATION = "ORGANIZATION",
    }

    export class Entity {
        constructor(
            private entityId: number,
            private accessLevel: ClientDataAccessRequest.AccessLevel,
        ) {}

        equals(other: Entity): boolean {
            return this.entityId === other.entityId && this.accessLevel === other.accessLevel;
        }
    }
}

export = ClientDataAccessRequest;
